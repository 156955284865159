import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import Hero from '../components/Hero';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import { sprinkles } from '../sprinkles.css';

import FastSimpleCompliant from '../images/fast-simple-compliant.svg';
import PartnershipAmico from '../images/index-partnership-amico.svg';
import SoftwareSoftSkillsAmico from '../images/index-software-soft-skills-amico.svg';
import ReadingListAmico1 from '../images/reading-list-amico-1.svg';

import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import { BlogTeaserSection } from '../components/BlogTeaser';
import ContentSection from '../components/ContentSection';
import CustomerLogos from '../components/CustomerLogos';
import FaqSection from '../components/FaqSection';
import FullWidthTabs from '../components/FullWidthTabs';
import SmartLink from '../components/Link';
import QuickInsights from '../components/QuickInsights';
import { Seo } from '../components/Seo';
import TestimonialSection, { TestimonialCard } from '../components/Testimonial';
import { Text, Title } from '../components/Text';
import { radius as borderRadius, width as themeWidth } from '../theme.css';

export const Head = () => <Seo />;

const IndexPage = ({
	data: { blogs, allFaqJson, allQuickInsightsJson, allTestimonialsJson },
}: {
	data: {
		sitePage: Queries.SitePage;
		locales: { edges: Queries.LocaleEdge[] };
		blogs: { edges: Queries.MdxEdge[] };
		allFaqJson: { nodes: Queries.FaqJson[] };
		allQuickInsightsJson: { nodes: Queries.QuickInsightsJson[] };
		allTestimonialsJson: { nodes: Queries.TestimonialsJson[] };
	};
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation('IndexPage');
	const isMobile = useMediaQuery({ maxWidth: themeWidth.tablet });
	const isTablet = useMediaQuery({ maxWidth: themeWidth.desktopHalf });
	const isDesktopHalf = useMediaQuery({ maxWidth: themeWidth.desktop });
	const isDekstop = useMediaQuery({ maxWidth: themeWidth.wide });

	const quickInsightsContent = {
		...allQuickInsightsJson?.nodes[0],
		insights: allQuickInsightsJson?.nodes[0].insights.map((insight) => ({
			...insight,
		})),
	};

	if (language === 'de') {
		const faq = (
			<FaqSection
				content={allFaqJson.nodes[0].entries.filter((e) => e.pages.includes('/'))}
				width="wide"
			/>
		);
		const soVerlaeuftDerProzess = (
			<Section padding gap="huge" width="xwide">
				<Title level="section" align="center">
					So läuft der Prozess
				</Title>
				<Section
					gap="huge"
					align="start"
					direction={isTablet ? 'vertical' : 'horizontal'}
					width="fill"
				>
					<Section direction="vertical" align="start" gap="medium" width="fill">
						<strong
							className={sprinkles({
								fontSize: {
									mobile: 'giant',
									desktopHalf: 'inYourFace',
								},
							})}
						>
							1
						</strong>
						<Title level="paragraph">Kostenlosen Beratungstermin buchen</Title>
						<Link
							key={1}
							to="https://my.meetergo.com/martinmeng/hinschg"
							className={ButtonRecipe({ size: 'medium', variant: 'primary' })}
						>
							Termin vereinbaren
						</Link>
					</Section>
					<Section direction="vertical" gap="medium" width="fill" align="start">
						<strong
							className={sprinkles({
								fontSize: {
									mobile: 'giant',
									desktopHalf: 'inYourFace',
								},
							})}
						>
							2
						</strong>
						<Title level="paragraph">
							Wir besprechen gemeinsam die Umsetzung des HinSchG in Ihrem Unternehmen
						</Title>
						<Text variant="primary">
							Ob interner oder externer Meldestellenbetrieb - wir finden die beste Lösung
							für Sie!
						</Text>
					</Section>
					<Section direction="vertical" gap="medium" width="fill" align="start">
						<strong
							className={sprinkles({
								fontSize: {
									mobile: 'giant',
									desktopHalf: 'inYourFace',
								},
							})}
						>
							3
						</strong>
						<Title level="paragraph">
							Wenn Sie sich für uns entscheiden, sind Sie schnellstens HinSchG-ready
						</Title>
						<Text variant="primary" strong>
							⌚ Innerhalb 48 Stunden Einsatzbereit
						</Text>
						<Text variant="primary">
							📣 Viele zusätzliche Services für noch mehr Hinweisgeberschutz verfügbar
						</Text>
					</Section>
				</Section>
			</Section>
		);
		const freeVideoConsultation = (
			<ContentSection
				padding
				width="xwide"
				titleLevel="subsection"
				title={{
					__html:
						'Was ist das Hinweisgeberschutzgesetz? Wen betrifft es? <strong>Was ist zu tun?</strong>',
				}}
				text={{
					__html:
						'In unserer kostenlosen Video-Beratung erklärt HinSchG-Experte Martin Meng <strong>alles was Sie wissen müssen</strong>.',
				}}
				cta1Text="Video ansehen"
				cta1Link="https://konfidal.eu/blog/hinweisgeberschutzgesetz-video-beratung/"
				image={
					<StaticImage
						className={sprinkles({
							borderRadius: 'medium',
						})}
						src="../images/hinschg-explain-video-teaser.png"
						alt="Hinweisgeberschutz erklärt - kostenloses online Beratungsvideo für Unternehmen"
					/>
				}
				// image={<><div style={{ padding:'75% 0 0 0', position:'relative' }}><iframe src="https://player.vimeo.com/video/882579047?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{ position: 'absolute', top:0, left:0, width: '100%', height: '100%', borderRadius: space.medium }} title="serious-play"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script></>
				// }
			/>
		);
		const testimonials = (
			<Section padding gap="huge" width="xwide">
				<Title level="section" align="center">
					Das berichten unsere Kunden
				</Title>
				<Section
					wrap
					gap="large"
					align="start"
					direction={isTablet ? 'vertical' : 'horizontal'}
					width="fill"
				>
					{allTestimonialsJson.nodes.map((t, i) => (
						<div
							className={sprinkles({
								borderRadius: 'medium',
								borderColor: 'border',
								borderStyle: 'solid',
								borderWidth: '1px',
								padding: {
									mobile: 'small',
									tablet: 'medium',
									desktopHalf: 'large',
								},
								width: {
									desktop: 'p30',
									desktopHalf: 'p42',
									mobile: 'fill',
								},
							})}
							key={i}
						>
							<TestimonialCard {...t} />
						</div>
					))}
				</Section>
			</Section>
		);
		const offersTabs = (
			<FullWidthTabs
				content={[
					{
						title: t('Tabs.Consulting.Title'),
						text: <Trans i18nKey="Tabs.Consulting.Text_html" />,
						ctaLabel: t('Tabs.Consulting.CtaLabel'),
						image: (
							<img
								width="100%"
								style={{ maxHeight: '100%' }}
								src={ReadingListAmico1}
								alt={t('Tabs.Consulting.ImageAlt')}
							/>
						),
					},
					{
						title: t('Tabs.Software.Title'),
						text: <Trans i18nKey="Tabs.Software.Text_html" />,
						ctaLabel: t('Tabs.Software.CtaLabel'),
						image: (
							<img
								width="100%"
								style={{ maxHeight: '100%' }}
								src={SoftwareSoftSkillsAmico}
								alt={t('Tabs.Software.ImageAlt')}
							/>
						),
					},
					{
						title: t('Tabs.FullService.Title'),
						text: <Trans i18nKey="Tabs.FullService.Text_html" />,
						ctaLabel: t('Tabs.FullService.CtaLabel'),
						image: (
							<img
								width="100%"
								style={{ maxHeight: '100%' }}
								src={PartnershipAmico}
								alt={t('Tabs.FullService.ImageAlt')}
							/>
						),
					},
				]}
			/>
		);

		let width = themeWidth.desktop;
		let videoSize = '1080';
		if (isMobile) {
			width = '100%';
			videoSize = '480';
		} else if (isTablet) {
			width = '100%';
			videoSize = '720';
		} else if (isDesktopHalf) {
			width = '100%';
			videoSize = '720';
		} else if (isDekstop) {
			width = themeWidth.desktopHalf;
			videoSize = '1080';
		}
		// const height = Number.parseInt(width.split('px')[0]) * 0.5625 + 'px';

		const video = (
			<Section
				direction="vertical"
				gap="larger"
				width="fill"
				justify="center"
				align="center"
				padding
			>
				<video
					width={width}
					// height={height}
					controls
					style={{ borderRadius: borderRadius.medium }}
				>
					<source
						src={`/video/konfidal_martin_introduction_desaturated_h264_compressed_${videoSize}.mp4`}
						type="video/mp4"
					/>
					Your browser does not support video playback
				</video>
				<SmartLink
					to="https://my.meetergo.com/martinmeng/hinschg"
					className={ButtonRecipe({
						size: 'large',
						variant: 'primary',
					})}
				>
					Termin vereinbaren
				</SmartLink>
				<Text>Kostenlos &#183; Ergebnisoffen &#183; Lösungorientiert</Text>
			</Section>
		);
		return (
			<Layout padding={false} limitWidth={false}>
				<Hero
					width="xwide"
					title={<Title level="title">Hinweisgeberschutz aus einer Hand</Title>}
					text={
						<Section direction="vertical" align="start" width="narrow">
							<Text strong>
								Wir machen Unternehmen rechtssicher nach Hinweisgeberschutzgesetz:
							</Text>
							<Text>✓ Moderne, Rechtskonforme Meldestellensoftware</Text>
							<Text>✓ Externer Meldestellenbetrieb nach HinSchG</Text>
							<Text>✓ Unschlagbares Preis-Leistungs-Verhältnis!</Text>
						</Section>
					}
					image={
						<img
							alt="konfidal: Fast simple compliant"
							src={FastSimpleCompliant}
							className={sprinkles({
								width: 'fill',
								minWidth: {
									mobile: 'three',
									tablet: 'fourhalf',
								},
							})}
						/>
					}
					actions={[
						<Link
							key={1}
							to="https://my.meetergo.com/martinmeng/hinschg"
							className={ButtonRecipe({ size: 'large', variant: 'primary' })}
						>
							Kostenlosen Beratungstermin buchen
						</Link>,
					]}
				/>
				<CustomerLogos />
				{video}
				{faq}
				{soVerlaeuftDerProzess}
				{testimonials}
				{blogs && (
					<BlogTeaserSection
						posts={blogs.edges
							.map(({ node }) => node)
							.sort(
								(a, b) =>
									(b.frontmatter?.lastmod &&
										a.frontmatter?.lastmod?.localeCompare(b.frontmatter?.lastmod)) ||
									0,
							)
							.slice(0, 2)}
					/>
				)}
				{offersTabs}
				{allQuickInsightsJson?.nodes[0] && <QuickInsights {...quickInsightsContent} />}
			</Layout>
		);
	} else {
		return (
			<Layout padding={false} limitWidth={false}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						{...{
							title: (
								<Title level="title">
									<Trans i18nKey="Hero.Title" />
								</Title>
							),
							text: <Trans i18nKey="Hero.Text_html" />,
							actions: [
								<Link
									key={1}
									to="https://my.meetergo.com/martinmeng/hinschg"
									className={ButtonRecipe({ size: 'large', variant: 'primary' })}
								>
									{t('Hero.Cta1Text')}
								</Link>,
								// <Link
								// 	key={2}
								// 	to="https://my.meetergo.com/martinmeng/hinschg"
								// 	className={ButtonRecipe({ size: 'large', variant: 'secondaryText' })}
								// >
								// 	Get advice now
								// </Link>,
							],
							image: (
								<img
									alt="konfidal: Fast simple compliant"
									src={FastSimpleCompliant}
									className={sprinkles({
										width: 'fill',
										minWidth: {
											mobile: 'three',
											tablet: 'fourhalf',
										},
									})}
								/>
							),
						}}
						width="wide"
					/>
					<CustomerLogos />
					<FullWidthTabs
						content={[
							{
								title: t('Tabs.Consulting.Title'),
								text: <Trans i18nKey="Tabs.Consulting.Text_html" />,
								ctaLabel: t('Tabs.Consulting.CtaLabel'),
								image: (
									<img
										width="100%"
										style={{ maxHeight: '100%' }}
										src={ReadingListAmico1}
										alt={t('Tabs.Consulting.ImageAlt')}
									/>
								),
							},
							{
								title: t('Tabs.Software.Title'),
								text: <Trans i18nKey="Tabs.Software.Text_html" />,
								ctaLabel: t('Tabs.Software.CtaLabel'),
								image: (
									<img
										width="100%"
										style={{ maxHeight: '100%' }}
										src={SoftwareSoftSkillsAmico}
										alt={t('Tabs.Software.ImageAlt')}
									/>
								),
							},
							{
								title: t('Tabs.FullService.Title'),
								text: <Trans i18nKey="Tabs.FullService.Text_html" />,
								ctaLabel: t('Tabs.FullService.CtaLabel'),
								image: (
									<img
										width="100%"
										style={{ maxHeight: '100%' }}
										src={PartnershipAmico}
										alt={t('Tabs.FullService.ImageAlt')}
									/>
								),
							},
						]}
					/>
					{allQuickInsightsJson?.nodes[0] && <QuickInsights {...quickInsightsContent} />}
					{blogs && <TestimonialSection testimonials={allTestimonialsJson?.nodes} />}
					<BlogTeaserSection
						posts={blogs.edges
							.map(({ node }) => node)
							.sort(
								(a, b) =>
									(b.frontmatter?.lastmod &&
										a.frontmatter?.lastmod?.localeCompare(b.frontmatter?.lastmod)) ||
									0,
							)
							.slice(0, 2)}
					/>
				</Section>
				<FaqSection
					content={allFaqJson.nodes[0].entries.filter((e) => e.pages.includes('/'))}
					width="wide"
				/>
			</Layout>
		);
	}
};
export default IndexPage;

export const pageQuery = graphql`
	query ($language: String!) {
		sitePage {
			context {
				i18n {
					originalPath
				}
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		blogs: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/blog/**" }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						published_at
						path
						author
						featured_image {
							src {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							alt
						}
					}
					excerpt(pruneLength: 250)
					internal {
						contentFilePath
					}
				}
			}
		}
		allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
		allQuickInsightsJson(filter: { language: { eq: $language } }) {
			nodes {
				title
				subtitle
				description
				insights {
					title
					text
					icon
				}
			}
		}
		allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["o-group", "ferox-legal"] } }
		) {
			nodes {
				name
				company
				text
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				companyLogoAlt
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				personImageAlt
				link
			}
		}
	}
`;
